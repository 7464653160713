"use client";

// Librarie
import moment from "moment";
import { Link } from "@/lib/i18n";
import { toast } from "react-toastify";
import Image from "next/image";
import * as m from "@/paraglide/messages";

// Component
import Logo from "../Logo/Logo";

export default function Footer(props) {
    return (
        <footer
            className={`dark:bg-bgDark-840 bg-gray-100 ${
                !props.withoutMarginTop && "mt-14"
            }`}
        >
            <div className="m-container">
                <div className="container mx-auto md:px-4 px-9 md:py-9 py-5 text-xs">
                    <div className="md:grid grid-cols-12">
                        <div className="col-span-4 mb-4 md:mt-0 mt-5">
                            <div className="dark:block hidden">
                                <Link href="/">
                                    <Logo ratio={0.8} white />
                                </Link>
                            </div>
                            <div className="dark:hidden block">
                                <Link href="/">
                                    <Logo ratio={0.8} />
                                </Link>
                            </div>
                            <div className="font-light dark:text-white mt-5">
                                For Believers.
                            </div>
                            <small className="block">
                                <span className="dark:text-lightBlue">
                                    {moment().format("Y")} ©{" "}
                                    <Link
                                        href="/legal-mentions"
                                        className="dark:text-lightBlue"
                                    >
                                        {m.Footer_legalMentions()}
                                    </Link>{" "}
                                    -{" "}
                                    <Link
                                        href="/privacy"
                                        className="dark:text-lightBlue"
                                    >
                                        {m.Footer_privacy()}
                                    </Link>
                                </span>
                            </small>
                            <div className="mt-4">
                                <Link
                                    href="https://certifopac.fr/qualiopi/certification/verification/?siren=950731331"
                                    target="_blank"
                                    rel="nofollow"
                                >
                                    {m.Footer_qualiopi()} 🇫🇷
                                </Link>
                            </div>
                            <Link
                                href="https://certifopac.fr/qualiopi/certification/verification/?siren=950731331"
                                target="_blank"
                                rel="nofollow"
                                className="inline-block"
                                title={m.Footer_qualiopi()}
                            >
                                <Image
                                    src="/images/site/assets/qualiopi.jpg"
                                    width={190}
                                    height={100}
                                    className="rounded-xl mt-3"
                                    alt="Qualiopi certification"
                                />
                            </Link>
                            <div className="w-2/3 mt-1 text-gray-700 dark:text-lightBlue">
                                {m.Footer_qualiopi_description()}
                            </div>
                        </div>
                        <div className="col-span-2 md:mt-0 mt-5">
                            <span className="uppercase font-bold dark:text-gray-50">
                                {m.Footer_accelerators()}
                            </span>
                            <ul>
                                <li className="pt-4">
                                    <Link
                                        href="/accelerators/rocket"
                                        className="dark:text-lightBlue"
                                    >
                                        {m.Footer_accelerators_rocket()}
                                    </Link>
                                </li>
                                <li className="pt-4">
                                    <Link
                                        href="https://belleannee.believemy.com/"
                                        className="dark:text-lightBlue"
                                    >
                                        {m.Footer_accelerators_newYear()}
                                    </Link>
                                </li>
                                <li className="pt-4">
                                    <Link
                                        href="/accelerators"
                                        className="dark:text-lightBlue"
                                    >
                                        {m.Footer_accelerators_founder()}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-span-2 md:mt-0 mt-5">
                            <span className="uppercase font-bold dark:text-gray-50">
                                {m.Footer_usefulLinks()}
                            </span>
                            <ul>
                                <li className="pt-4">
                                    <Link
                                        href="/join-instructors"
                                        className="dark:text-lightBlue"
                                    >
                                        {m.Footer_usefulLinks_becomingInstructor()}
                                    </Link>
                                </li>
                                <li className="pt-4">
                                    <Link
                                        href="/join-mentors"
                                        className="dark:text-lightBlue"
                                    >
                                        {m.Footer_usefulLinks_becomingMentor()}
                                    </Link>
                                </li>
                                {/* <li className="pt-4">
                                <Link
                                    href="/entrepreneurs"
                                    className="dark:text-lightBlue"
                                >
                                    Entrepreneurs
                                </Link>
                            </li> */}
                                {/* <li className="pt-4">
                                    <Link
                                        href="/developers"
                                        className="dark:text-lightBlue"
                                    >
                                        Développeurs
                                    </Link>
                                </li>
                                <li className="pt-4">
                                    <Link
                                        href="/education"
                                        className="dark:text-lightBlue"
                                    >
                                        Étudiants
                                    </Link>
                                </li> */}
                                <li className="pt-4">
                                    <Link
                                        href="/pricing"
                                        className="dark:text-lightBlue"
                                    >
                                        {m.Footer_usefulLinks_pricing()}
                                    </Link>
                                </li>
                                <li className="pt-4">
                                    <Link
                                        href="/articles"
                                        className="dark:text-lightBlue"
                                    >
                                        {m.Footer_usefulLinks_articles()}
                                    </Link>
                                </li>
                                <li className="pt-4">
                                    <Link
                                        href="/glossaries/html-et-css"
                                        className="dark:text-lightBlue"
                                    >
                                        {m.Footer_usefulLinks_glossaries()}
                                    </Link>
                                </li>

                                <li className="pt-4">
                                    <Link
                                        href="/courses"
                                        className="dark:text-lightBlue"
                                    >
                                        {m.Footer_usefulLinks_courses()}
                                    </Link>
                                </li>
                                <li className="pt-4">
                                    <Link
                                        href="/community"
                                        className="dark:text-lightBlue"
                                    >
                                        {m.Footer_usefulLinks_community()}
                                    </Link>
                                </li>
                                <li className="pt-4">
                                    <Link
                                        href="/mentoring"
                                        className="dark:text-lightBlue"
                                    >
                                        {m.Footer_usefulLinks_mentoring()}
                                    </Link>
                                </li>
                                <li className="pt-4">
                                    <Link
                                        href="/events"
                                        className="dark:text-lightBlue"
                                    >
                                        {m.Footer_usefulLinks_events()}
                                    </Link>
                                </li>
                                <li className="pt-4">
                                    <Link
                                        href="https://podcasts.apple.com/us/podcast/conversation-with/id1771384047"
                                        className="dark:text-lightBlue"
                                        target="_blank"
                                    >
                                        {m.Footer_usefulLinks_podcast_conversationWith()}
                                    </Link>
                                </li>
                                <li className="pt-4">
                                    <Link
                                        href="https://podcasts.apple.com/us/podcast/revues-de-livres/id1771618267"
                                        className="dark:text-lightBlue"
                                        target="_blank"
                                    >
                                        {m.Footer_usefulLinks_podcast_bookReviews()}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className="col-span-2 md:mt-0 mt-5">
                            <span className="uppercase font-bold dark:text-gray-50">
                                {m.Footer_followUs()}
                            </span>
                            <ul>
                                <li className="pt-4">
                                    <Link
                                        href="https://www.tiktok.com/@le.developpeur"
                                        className="dark:text-lightBlue"
                                    >
                                        TikTok
                                    </Link>
                                </li>
                                <li className="pt-4">
                                    <Link
                                        href="https://twitter.com/BelievemyFR"
                                        className="dark:text-lightBlue"
                                    >
                                        Twitter
                                    </Link>
                                </li>
                                <li className="pt-4">
                                    <Link
                                        href="https://instagram.com/usebelievemy"
                                        className="dark:text-lightBlue"
                                    >
                                        Instagram
                                    </Link>
                                </li>
                                <li className="pt-4">
                                    <Link
                                        href="https://www.linkedin.com/company/believemy/"
                                        className="dark:text-lightBlue"
                                    >
                                        LinkedIn
                                    </Link>
                                </li>
                                <li className="pt-4">
                                    <Link
                                        href="https://www.youtube.com/@believemy"
                                        className="dark:text-lightBlue"
                                    >
                                        Youtube
                                    </Link>
                                </li>
                                <li className="pt-4">
                                    <a
                                        href="mailto:hello@believemy.com"
                                        className="dark:text-lightBlue inline-block"
                                    >
                                        hello@believemy.com
                                    </a>
                                </li>
                                <li className="pt-4">
                                    <Link
                                        href="/press"
                                        className="dark:text-lightBlue"
                                    >
                                        {m.Footer_followUs_press()}
                                    </Link>
                                </li>{" "}
                            </ul>
                        </div>
                        <div className="col-span-2 md:mt-0 mt-5">
                            <span className="uppercase font-bold dark:text-gray-50">
                                {m.Footer_weLoveThem()}
                            </span>
                            <ul>
                                <li className="pt-4">
                                    <Link
                                        href="/r/le-guide-complet-sur-flexbox-css"
                                        className="dark:text-lightBlue"
                                    >
                                        Le guide complet sur Flexbox CSS
                                    </Link>
                                </li>
                                <li className="pt-4">
                                    <Link
                                        href="/r/javascript-vs-typescript-lequel-est-le-meilleur"
                                        className="dark:text-lightBlue"
                                    >
                                        JavaScript vs TypeScript : lequel est le
                                        meilleur ?
                                    </Link>
                                </li>
                                <li className="pt-4">
                                    <Link
                                        href="/r/respecter-les-principes-solid-avec-react"
                                        className="dark:text-lightBlue"
                                    >
                                        Respecter les principes SOLID avec React
                                    </Link>
                                </li>
                                <li className="pt-4">
                                    <Link
                                        href="/r/les-nouveautes-de-react-19"
                                        className="dark:text-lightBlue"
                                    >
                                        Les nouveautés de React 19
                                    </Link>
                                </li>
                                <li className="pt-4">
                                    <Link
                                        href="/course/formation-nextjs"
                                        className="dark:text-lightBlue"
                                    >
                                        Formation NextJS
                                    </Link>
                                </li>
                                <li className="pt-4">
                                    <Link
                                        href="/course/formation-react"
                                        className="dark:text-lightBlue"
                                    >
                                        Formation ReactJS
                                    </Link>
                                </li>
                                <li className="pt-4">
                                    <Link
                                        href="/course/formation-JavaScript"
                                        className="dark:text-lightBlue"
                                    >
                                        Formation JavaScript
                                    </Link>
                                </li>
                                <li className="pt-4">
                                    <Link
                                        href="/course/formation-html"
                                        className="dark:text-lightBlue"
                                    >
                                        Formation HTML et CSS
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        {/* <div className="col-span-2 md:mt-0 mt-5">
                        <span className="uppercase font-bold dark:text-white">
                            Nous contacter
                        </span>
                        <ul>
                        </ul>
                    </div> */}
                    </div>
                </div>
            </div>
        </footer>
    );
}
